
import { React, useState, useEffect } from 'react'

import { HashLink as Link } from 'react-router-hash-link';

import { lP, oP, sT, APPMODE } from '../../main.js'

import './MainHeader.min.css'
import './MMainHeader.min.css'
import '../../main.min.css'

import Cookies from 'universal-cookie'

const cookies = new Cookies()


export default function MainHeader(props) {


    useEffect(() => {
    }, [])

    useEffect(() => {
        // try {
        //     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
        //         document.querySelector(".orientationModal").style.display = "block"
        //     } else {
        //         document.querySelector(".orientationModal").style.display = "none"
        //     }
        // } catch (e) {
        //     console.log(e)
        // }


        // const orientationlistener = event => {
        //     console.log("rotate")
        //     try {
        //         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.screen.orientation.type != "portrait-primary") {
        //             document.querySelector(".orientationModal").style.display = "block"
        //         } else {
        //             document.querySelector(".orientationModal").style.display = "none"
        //         }
        //     } catch (e) {
        //         console.log(e)
        //     }

        // }
        // window.addEventListener('orientationchange', orientationlistener)

        // return () => {
        //     document.removeEventListener("orientationchange", orientationlistener)
        // }

    })

    let selsite = props.selectedsite
    function setSelCN(lsite) {
        return (lsite == selsite) ? "selsite" : "";
    }

    return (
        <div>
            {(APPMODE == "pre") ?
                <div className="DEVinfo" style={{ backgroundColor: "blue !important" }}>
                    VORABVERSION
                </div> : (APPMODE != "prod") ?
                    <div className="DEVinfo" >
                        🛠️ ACHTUNG: ENTWICKLUNGSVERSION 🛠️
                    </div> : <div></div>}
            {/* <div className="orientationModal">
                Bitte nutze unsere Webseite nur im Hochformat.
            </div> */}
            <div className='mainheaderdiv noselect' style={{ marginTop: (APPMODE != "prod") ? "0" : "0" }}>
                {/* 20px */}
                {/* <a href='/' className={setSelCN("about")}>Über mich</a>
                <a href='/leistungen' className={setSelCN("services")}>Leistungen</a>
                <a href='/' className={setSelCN("kontakt")}>Kontakt</a> */}

                <Link smooth to='/#aboutme' className={setSelCN("about")}>Über mich</Link>
                <Link to='/leistungen' className={setSelCN("services")}>Leistungen</Link>
                <Link smooth to='#contactme' className={setSelCN("kontakt")}>Kontakt</Link>
            </div>

        </div>
    )
}