import { React, useState, useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { motion, useAnimation } from "framer-motion";

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'

import { lP, oP, sT, boxvar1, boxvar2, boxvar3 } from '../../main.js'

import '../../main.min.css'
import './Home.min.css'
import './MHome.min.css'

import logo_main from '../../assets/pictures/logos/naturheilkundekonefal.webp'
import home_img from '../../assets/pictures/sectionimages/home_img_natural_ai.webp'
import heilkunde_formedtwo_ai from '../../assets/pictures/sectionimages/heilkunde_formedtwo_ai.webp'
import alena_formedone from '../../assets/pictures/sectionimages/alena_formedone.webp'
import blatt_1 from '../../assets/pictures/sectionimages/blatt-1.webp'
import blatt_2 from '../../assets/pictures/sectionimages/blatt-2.webp'
import blatt_3 from '../../assets/pictures/sectionimages/blatt-3.webp'


export default function Home() {

    const blattvar1 = {
        visible: { opacity: 1, scale: 1, x: 0, rotate: 30, transition: { duration: 0.3, ease: "easeOut", delay: 0.1 } },
        hidden: { opacity: 0.9, scale: 0.8, x: 0, rotate: 0, transition: { duration: 0.3, delay: 0.1 } },
    }
    const blattvar2 = {
        visible: { opacity: 1, scale: 1, x: 0, rotate: 30, transition: { duration: 0.4, ease: "easeOut", delay: 0.5 } },
        hidden: { opacity: 0.9, scale: 0.8, x: 0, rotate: 0, transition: { duration: 0.4, ease: "easeOut", delay: 0.5 } },
    }
    const blattvar3 = {
        visible: { opacity: 1, scale: 1, x: 0, rotate: 30, transition: { duration: 0.3, ease: "easeOut", delay: 0.25 } },
        hidden: { opacity: 0.9, scale: 0.8, x: 0, rotate: 0, transition: { duration: 0.3, ease: "easeOut", delay: 0.25 } },
    }

    return (
        <div className='mainCon' id='mainCon'>

            <img alt='Logo der Naturheilkundepraxis' className='logo_main_header' src={logo_main} onClick={() => lP('/')}></img>
            <MainHeader selectedsite={""} />

            <section className='sec_start' id='sec_start--home'>

                <motion.img variants={blattvar1} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} alt='KI generiertes Pflanzenblatt (1)' className='blatt1' src={blatt_1}></motion.img>
                <motion.img variants={blattvar1} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-150px" }} alt='KI generiertes Pflanzenblatt (2)' className='blatt2' src={blatt_2}></motion.img>
                <motion.img variants={blattvar1} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-250px" }} alt='KI generiertes Pflanzenblatt (3)' className='blatt3' src={blatt_3}></motion.img>

                <motion.div
                    variants={boxvar1}
                    initial="hidden"
                    // animate="visible"
                    exit="hidden"
                    whileInView="visible"
                    viewport={{ once: false }}
                    className="box1"

                >
                    <img alt='KI generiertes Bild von einer Frau, die Blätter in der Hand hält und Medizin um sich stehen hat' className='sec_start_img' src={home_img}></img>
                </motion.div>

                <motion.div
                    variants={boxvar1}
                    initial="hidden"
                    exit="hidden"
                    whileInView="visible"
                    viewport={{ once: false, margin: "-100px" }}
                    className="box2"
                >
                    <p className='quote_kneipp'>„Wer nicht jeden Tag etwas für seine Gesundheit aufbringt,<br />
                        muss eines Tages sehr viel Zeit für die Krankheit aufbringen“<br />
                        (Sebastian Kneipp)</p>
                </motion.div>
                <motion.div variants={boxvar3} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }}>
                    <Link smooth to='#contactme' >
                        <CTAButton className="sec_start_btn" hideArrow={true} title={"Jetzt Termin vereinbaren"} onclick={() => { }}></CTAButton>
                    </Link>
                </motion.div>
                {/* <CTAButton className="sec_start_btn" hideArrow={true} title={"Mehr über meine Leistungen erfahren"} onclick={() => lP('/leistungen')}></CTAButton> */}

            </section>
            <section className='sec_next' id='aboutme'>
                {/* sec_next--home--about */}
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Über mich</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von Alena Konefal, der Praxisinhaberin' className='generalgrid_img' src={alena_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            <b>Mein Name ist Alena Konefal,</b><br />
                            ich bin  40 Jahre alt, verheiratet und stolze Mutter von zwei
                            wundervollen Kindern. Seit über 20 Jahren arbeite ich
                            mit Leidenschaft im Gesundheitswesen als Kinderkrankenschwester.
                            Durch eigene positive Erfahrungen wurde meine Faszination
                            sehr bald für die Naturheilkunde geweckt und nun ist es mein Ziel,
                            die Schulmedizin mit alternativen Behandlungsmöglichkeiten
                            zu verbinden.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        In meinem Studium zur Heilpraktikerin habe ich zahlreiche naturheilkundliche Methoden kennengelernt,
                        die nicht nur meine eigene Gesundheit unterstützt haben, sondern auch großes Potenzial für die
                        Förderung von Wohlbefinden und Vitalität bieten. In meiner Praxis möchte ich mein Wissen, meine
                        Erfahrungen und meine Leidenschaft für die Verbindung von Schulmedizin und Naturheilkunde teilen.
                        <br /><br />
                        Mein Ziel ist es, alternative Behandlungsmöglichkeiten in den Fokus zu rücken und Ihnen diese anzubieten.
                        Ich zeige Ihnen wie Sie die Vorteile beider Ansätze für Ihre Gesundheit optimal nutzen können.
                        Gerne begleite ich Sie auf Ihrem Weg zu mehr Gesundheit und Wohlbefinden!
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--home--whatis'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Was ist Naturheilkunde?</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='KI generiertes Bild von Pflanzen und Medizin auf einem Tisch' className='generalgrid_img' src={heilkunde_formedtwo_ai}></img>
                        <div className='generalgrid_firsttextwCTA_cont'>
                            <p className='generalgrid_firsttextwCTA'>
                                {/* <p className='generalgrid_firsttext'> */}
                                Die Naturheilkunde ist eine faszinierende und ganzheitliche
                                Herangehensweise zur Förderung der Gesundheit. Sie basiert auf
                                der Überzeugung, dass der Körper eine erstaunliche Fähigkeit
                                zur Selbstheilung besitzt. Durch den Einsatz natürlicher Mittel
                                wie Kräuter, Heilpflanzen und alternative Therapien wird der
                                Organismus gestärkt und in sein Gleichgewicht gebracht.
                            </p>
                            <CTAButton className="generalgrid_cta" hideArrow={false} title={"Alle Leistungen"} onclick={() => lP('/leistungen')}></CTAButton>

                        </div>

                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Diese Methode betrachtet den Menschen als Einheit von Körper, Geist und Seele,
                        wodurch nicht nur Symptome, sondern auch deren Ursachen behandelt werden.
                        Die Naturheilkunde setzt auf sanfte und nebenwirkungsarme Ansätze, die die Selbstregulation
                        des Körpers unterstützen. Die Ernährung spielt dabei eine zentrale Rolle, indem sie auf eine ausgewogene,
                        naturbelassene und vollwertige Nahrung setzt. Durch die Anwendung von bewährten Heilmethoden
                        wie Akupunktur, Homöopathie und Phytotherapie wird die individuelle Lebenskraft gestärkt.
                        <br /><br />
                        In der Naturheilkunde wird der Mensch als Teil seines Umfelds betrachtet, wodurch die Verbindung
                        zur Natur und die positiven Einflüsse dieser auf die Gesundheit betont werden. Ihr holistischer Ansatz
                        eröffnet Möglichkeiten zur Vorbeugung von Krankheiten und fördert ein nachhaltiges Wohlbefinden.
                    </motion.p>
                </div>
            </section>

            <MainFooter />
        </div >
    )
}