import { React } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link';
import { motion, useAnimation } from "framer-motion";

import MainHeader from '../../components/MainHeader/MainHeader.js'
import MainFooter from '../../components/MainFooter/MainFooter.js'
import CTAButton from '../../components/CTAButton/CTAButton.js'

import { lP, oP, sT, boxvar1, boxvar2, boxvar3 } from '../../main.js'

import '../../main.min.css'
import './Services.min.css'
import './MServices.min.css'

import logo_main from '../../assets/pictures/logos/naturheilkundekonefal.webp'
import regucell_formedone from '../../assets/pictures/sectionimages/regucell_formedone.webp'
import ohrakupunktur_formedone from '../../assets/pictures/sectionimages/ohrakupunktur_formedone.webp'
import infusion_formedone from '../../assets/pictures/sectionimages/infusion_formedone.webp'
import aderlass_formedone from '../../assets/pictures/sectionimages/aderlass_formedone.webp'
import driaspa_formedone from '../../assets/pictures/sectionimages/drispa_formedone.webp'
import cellsymbiose_formedone from '../../assets/pictures/sectionimages/cellsymbiose_formedone.webp'
import hormoncoach_formedone from '../../assets/pictures/sectionimages/hormoncoach_formedone.webp'


export default function Services() {

    return (
        <div className='mainCon' id='mainCon'>

            <img alt='Logo der Naturheilkundepraxis' className='logo_main_header' src={logo_main} onClick={() => lP('/')}></img>
            <MainHeader selectedsite={"services"} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Leistungen</title>
                <meta property="og:title" content="Leistungen" />
                <meta property="og:description" content="Hier finden Sie alle Leistungen, die ich in meiner Praxis anbiete." />
            </Helmet>
            <section className='sec_start' id='sec_start--home'>

                <motion.div
                    variants={boxvar1} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false }} className="box1">
                    <h1 className='sec_start_title'> Diese Leistungen biete ich in meiner Praxis an</h1>
                </motion.div>
                <motion.div variants={boxvar3} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }}>
                    <Link smooth to='#contactme' >
                        <CTAButton className="sec_start_btn" hideArrow={true} title={"Jetzt Termin vereinbaren"} onclick={() => { }}></CTAButton>
                    </Link>
                </motion.div>
            </section>
            <section className='sec_next' id='sec_next--services--Regucell'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Microstromtherapie / Regucell</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einem Skelett, das vor einem Herzmonitor läuft' className='generalgrid_img' src={regucell_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Die Microstromtherapie ist eine nicht-invasive Methode,
                            die schwache elektrische Ströme verwendet, um verschiedene
                            gesundheitliche Vorteile zu erzielen. Mit niedrigen Stromstärken,
                            ähnlich den natürlichen elektrischen Signalen des Körpers,
                            fördert diese Therapie die Schmerzlinderung, Entzündungshemmung,
                            Zellregeneration und Muskelregeneration. Durch die Platzierung
                            von Elektroden an spezifischen Stellen des Körpers werden die
                            natürlichen bioelektrischen Prozesse stimuliert, um die Heilung
                            zu beschleunigen. Die Anwendungsbereiche reichen von der
                            Schmerztherapie bis zur Hautverjüngung. Besonders die Basenwickel
                            in der Kombination mit dem Mikrostrom haben eine sehr schnelle
                            Entsäuerung des Körpers zur Folge.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--Ohrakupunktur'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Ohrakupunktur</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einer Nadel im Ohr' className='generalgrid_img' src={ohrakupunktur_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Entdecken Sie die therapeutischen Vorzüge der Ohrakupunktur
                            in meiner Praxis! Ich setze diese bewährte alternative Heilmethode ein,
                            um gezielt auf Ohrpunkte einzuwirken und eine effektive
                            Regulation des Energieflusses im Körper zu fördern.
                            Die Anwendung von feinen Nadeln an spezifischen Ohrpunkten
                            kann Schmerzlinderung, Stressreduktion, verbesserten Schlaf
                            und Unterstützung bei der Raucherentwöhnung bewirken.
                            Entdecken Sie in der Ohrakupunktur einen Schlüssel zu mehr
                            innerer Balance und Vitalität.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--Infusionstherapien'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Infusionstherapien</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einer Infusion mit Früchten' className='generalgrid_img' src={infusion_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Infusionstherapien umfassen Tropfinfusionen, die direkt in
                            die Vene gegeben werden und sich besonders in der
                            naturheilkundlichen Entgiftung sowie der Stärkung des Immunsystems
                            bewährt haben. Dabei ist jeder Wirkstoff der als Infusion verabreicht
                            wird zu 100% bioverfügbar. Beispiele hierfür sind Vitamin C Infusionen,
                            Infusionen zur Entsäuerung oder Entgiftung. Abhängig von
                            individuellen Bedürfnissen, Anliegen oder
                            Blutuntersuchungsergebnissen wird die passende Infusion ausgewählt.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Für die Immunstimulation oder bei belastenden Krankheitsbildern ist häufig eine Vitamin C Infusion
                        angezeigt. Infusionen zur Entsäuerung mit Natriumhydrogencarbonat-Lösung werden ebenfalls
                        oft verwendet. Unsere moderne, oft ungesunde Lebensweise, Umweltbelastungen und stressige
                        Routinen können den Körper auslaugen und den Säure-Basen-Haushalt aus dem Gleichgewicht bringen,
                        was letztlich den gesamten Stoffwechsel beeinträchtigt.
                        <br /><br />
                        Die tägliche Übersäuerung durch ungesunde Ernährung, Stress und Umweltbelastungen führt oft dazu,
                        dass der Körper Schwierigkeiten hat, die anfallenden Säuren vollständig auszuscheiden. Diese werden
                        als saure Stoffwechselschlacken im Bindegewebe abgelagert, was zu einer fortschreitenden
                        Übersäuerung des Körpers führen kann. Ideal für die Entsäuerung des Körpers ist die Kombination
                        von Infusionen und Basenwickel mit Mikrostrom.
                        Selbstverständlich gehören auch Vitamin-, Mineralstoff- und Aminosäuren Infusionen zu meinem
                        Leistungsspektrum, in der Regel basierend auf Blutbefunden, um die speziellen Bedürfnisse und
                        Anforderungen jedes Patienten individuell zu berücksichtigen und das bestmögliche Ergebnis zu erzielen.
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--Aderlass'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Aderlass</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einem Glas voller Blut' className='generalgrid_img' src={aderlass_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Der Aderlass, eine traditionsreiche Heilpraktik, erlebt heutzutage
                            eine erneute Wertschätzung, insbesondere im Rahmen der
                            naturheilkundlichen Betrachtung und unter Einbezug der Lehren
                            von Hildegard von Bingen. Diese jahrhundertealte Methode betont
                            positiv die heilende Wirkung auf die Blutzirkulation und das
                            Gleichgewicht der Körpersäfte. Nach dieser naturheilkundlichen
                            Perspektive kann der Aderlass dazu beitragen, stagnierende Säfte im
                            Körper zu regulieren und das Gleichgewicht wiederherzustellen.
                            Durch die gezielte Entnahme einer kleinen Menge Blutes werden
                            potenziell belastende Substanzen aus dem Kreislauf entfernt. Der
                            Aderlass wird daher als eine Möglichkeit zur natürlichen Entgiftung
                            betrachtet, um mehr Balance und Wohlbefinden zu fördern.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Der Aderlass reinigt und entgiftet den gesamten Organismus, öffnet die körpereigene Apotheke für die
                        Selbstheilung und beseitigt Heilungsblockaden. Er hilft gegen Bluthochdruck, erhöhtes Cholesterin,
                        Hormonmangel, Immunschwäche, Blutarmut, Arteriosklerose, Toxine und Stoffwechselschlacken.
                        Sämtliche Körperzellen und Gewebe werden besser durchblutet, gereinigt, regeneriert und vitalisiert.
                        Wissenschaftliche Studien bestätigen heute, dass es kaum eine Krankheit gibt, bei der ein Aderlass
                        nicht vorbeugend oder unterstützend wirken könnte.
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--Cellsymbiosistherapie'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Cellsymbiosistherapie</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einer blauen Zelle' className='generalgrid_img' src={cellsymbiose_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Die Cellsymbiosistherapie nach Dr. Heinrich Kremers ist eine ganzheitliche Therapieform,
                            die auf den Prinzipien der Cellsymbiosislehre basiert.
                            Dr. Heinrich Kremers entwickelte diese Therapie mit dem Ziel,
                            die Zellgesundheit zu fördern und die Selbstheilungskräfte des Körpers zu aktivieren.
                            Die Cellsymbiosislehre geht davon aus, dass eine optimale Kommunikation
                            zwischen den Zellen entscheidend für die Gesundheit ist. Störungen in dieser Kommunikation können
                            zu verschiedenen Beschwerden und Krankheiten führen. Die Cellsymbiosistherapie setzt daher darauf,
                            die Zellfunktionen zu optimieren und die symbiotische Beziehung zwischen den Zellen zu stärken.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Die Therapie umfasst verschiedene Maßnahmen, darunter eine individuell angepasste Ernährung,
                        gezielte Nahrungsergänzung, Entgiftungsmethoden und die Regulation von Stressfaktoren.
                        Ein zentraler Aspekt ist die Förderung der Mitochondrien, den Kraftwerken der Zellen,
                        um die Energieproduktion zu steigern.
                        <br /><br />
                        Die Cellsymbiosistherapie betrachtet den Menschen als Ganzes und berücksichtigt sowohl
                        körperliche als auch psychische Aspekte. Sie setzt auf eine nachhaltige Optimierung der
                        Zellgesundheit, um das Wohlbefinden zu steigern und die Anfälligkeit für Krankheiten
                        zu reduzieren.

                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--Hormoncoaching'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Hormoncoaching</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Fiktives Bild von einer Frau, die auf Steinen balanciert' className='generalgrid_img' src={hormoncoach_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Die Hormontherapie aus naturheilkundlicher Sicht konzentriert sich auf die Regulation
                            und den Ausgleich hormoneller Prozesse im Körper,
                            insbesondere bei Menschen mit hormonellen Beschwerden und Erkrankungen.
                            Dabei legt sie Wert auf den Einsatz körperidentischer Hormone sowie natürlicher
                            Grundbausteine wie Aminosäuren und das oft verteufelte Cholesterin.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Hormone werden als entscheidende Botenstoffe betrachtet,
                        die das komplexe Zusammenspiel verschiedener Organsysteme beeinflussen.
                        Diese Therapie geht über die bloße Behandlung von Symptomen hinaus und widmet sich intensiv
                        den Ursachen für hormonelle Dysbalancen. Dies geschieht durch eine ganzheitliche Betrachtung
                        des endokrinen Drüsensystems und die Harmonisierung feinstofflicher Aspekte mittels
                        natürlicher Behandlungsmethoden.
                        <br /><br />
                        Die Schwerpunkte der Therapie umfassen die Rolle von Hormonen in der embryonalen Entwicklung,
                        in der Frauen- und Männergesundheit, in der Immunabwehr sowie ihre Bedeutung
                        für Psyche und Gehirnfunktionen, Burnout, gesunden Schlaf, Stoffwechsel und Zellregeneration,
                        einschließlich der Hormonsituation im Alter.
                    </motion.p>
                </div>
            </section>

            <section className='sec_next' id='sec_next--services--DripSpa'>
                <div className='generalgrid_cont'>
                    <motion.h2 variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_title'>Drip Spa Infusionen</motion.h2>
                    <motion.div variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_firstsection'>
                        <img alt='Bild von einem Infusionsbeutel mit Obst als Inhalt' className='generalgrid_img' src={driaspa_formedone}></img>
                        <p className='generalgrid_firsttext'>
                            Der aktuelle Trend der DripSpa-Infusionen aus den USA verleiht
                            uns in kürzester Zeit ein Gefühl von Schönheit, Vitalität, Energie
                            und Gesundheit. Diese innovative Methode, die nicht nur
                            von Prominenten geschätzt wird, ermöglicht die direkte
                            Zufuhr von Vitaminen, Mineralstoffen und Antioxidantien in
                            die Blutbahn, wo sie dem Körper sofort zur Verfügung stehen.
                        </p>
                    </motion.div>
                    <motion.p variants={boxvar2} initial="hidden" exit="hidden" whileInView="visible" viewport={{ once: false, margin: "-50px" }} className='generalgrid_moretext'>
                        Nach einem individuellen Beratungsgespräch wählen wir die passende Infusion aus. Mithilfe einer
                        isotonischen Kochsalz-Lösung werden verschiedene Einzelstoffe oder Wirkstoffkombinationen infundiert.
                        Vitamin C unterstützt die körpereigene Abwehr, Coenzym Q10 und Taurin reduzieren
                        Erschöpfungserscheinungen. Die Vitamine C und E sowie Silicium stärken Haut, Haare und Nägel,
                        während die Vitamine B6 und B12, L-Carnitin und Magnesium den Fettabbau begünstigen.
                        <br /><br />
                        <b>Beauty-Infusion:</b><br />
                        Diese Drip-Spa-Anwendung stärkt mit Methionin das Bindegewebe, baut Kollagen mit Arginin auf,
                        wirkt antioxidativ dank Taurin und reguliert den Flüssigkeitshaushalt. Biotin fördert die Zellerneuerung von
                        Haut, Haaren und Nägeln.
                        <br /><br />
                        <b>Myers-Infusion:</b><br />
                        Der berühmte Myers-Cocktail, ein Klassiker unter den Vitamin-Infusionen, enthält Vitamine
                        B1, B3, B5, B6, B12, Vitamin C, Kalzium und Magnesium. Er soll die Hautalterung verlangsamen und
                        gleichzeitig Depressionen sowie Migräne vorbeugen.
                        <br /><br />
                        <b>AfterParty-Infusion:</b><br />
                        Basierend auf Vitamin C und Elektrolyten, stimuliert diese Infusion die organischen Entgiftungsprozesse,
                        mildert Hangover-Symptome und liefert dem Körper nach einer Party verlorene Vitamine und
                        Mineralstoffe zurück.
                        <br /><br />
                        <b>Detox-Infusion:</b><br />
                        Diese Mischung aus Aminosäuren, Magnesium, Kupfer und Mangan regt die Entgiftungsprozesse an
                        und unterstützt Detox-Kuren oder Diäten.
                        <br /><br />
                        <b>Immun-Infusion:</b><br />
                        Mit hochdosierten Wirkstoffen wie Vitamin C, Lysin, Methionin, L-Carnitin und Spurenelementen stärkt
                        diese Infusion das Immunsystem. Besonders geeignet bei Allergien und als Schutz vor Viren und
                        Bakterien zu Beginn der Erkältungssaison.
                        <br /><br />
                        <b>Entspannungs-Infusion:</b><br />
                        Die Anti-Stress-Mischung enthält Elektrolyte, Magnesium, Calcium, Kalium und Glycin. Carnitin
                        gibt Kraft für Körper, Geist und Psyche, steigert die Vitalität und wirkt gegen Stress. Taurin stärkt die Nerven
                        und wirkt gegen körperliche und geistige Überbelastung.
                        <br /><br />
                        <b>Shots:</b><br />
                        <ul>
                            <li>Vitamin-C-Shot</li>
                            <li>Vitamin-B-12-Shot</li>
                            <li>Glutathion-Shot bzw. -Injektion</li>
                        </ul>
                    </motion.p>
                </div>
            </section>

            <MainFooter />
        </div>
    )
}