import { React } from 'react'

import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import CTAButton from '../../components/CTAButton/CTAButton'

import './Page404.min.css'
import './MPage404.min.css'
import '../../main.min.css'


import logo_main from '../../assets/pictures/logos/naturheilkundekonefal.webp'


import { lP } from '../../main.js'

export default function Page404() {

    return (
        <div className='mainCon' id='mainCon'>
            <section className='sec_start' id='sec_start--page404'>

                <img alt='Logo der Naturheilkundepraxis' className='logo_main_header' src={logo_main} onClick={() => lP('/')}></img>

                <MainHeader pagetitle="" hideProfile={false} hideMenu={false} />

                <h1>Irren ist menschlich.</h1>
                <h2>ERROR 404 - Seite nicht gefunden</h2>
                <div className='infocontainer'>
                    <p>
                        Kehren Sie entweder zur vorherigen Seite oder zur <a href="/">Startseite</a> zurück.<br />
                        Sollte der Fehler erneut auftreten, kontaktieren Sie uns bitte.<br />
                        Am besten erreichen Sie uns uns über unseren Kontaktbereich<br />
                        oder alternativ per Mail an den Webdesigner <a href="mailto:info@tonetwork.de">info@tonetwork.de</a>.<br />
                        Wir stehen Ihnen gerne zur Seite 😉
                    </p>
                </div>
                <br />
                <CTAButton className='start_cta' title="Zur Startseite" onclick={() => lP('/')} darkcta={true} />
            </section>
            <MainFooter />
        </div>
    )
}