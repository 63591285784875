import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { React, useEffect, useState } from 'react'

import './App.min.css'
import './MApp.min.css'


import Cookies from 'universal-cookie'

import Home from './pages/Home/Home'
import Page404 from './pages/Page404/Page404'


import { generateUUID, getBrowser } from './main.js'

import Services from './pages/Services/Services.js'
import Impressum from './pages/Impressum/Impressum.js'

const cookies = new Cookies()

export default function App() {
    useEffect(() => {
        try {
            // window.screen.orientation.lock("portrait")
        } catch (e) {
            console.log(e)
        }
    }, [])

    let cur_userid = cookies.get('userid')
    let loggedin = (cur_userid != "" && cur_userid != undefined)

    var agentinfo = getBrowser(navigator.userAgent)
    var location = window.location

    // console.log(agentinfo.browser)
    // console.log(agentinfo.agent)
    // console.log(location.pathname)



    return (
        <Router>
            {/* basename={'/'} */}
            <Switch>

                <Route path="/leistungen" component={Services} />
                <Route path="/impressum" component={Impressum} />

                <Route path="/" component={Home} exact />
                <Route path="/*" component={Page404} />
                <Route component={Page404} />
            </Switch>
        </Router>
    )
}

